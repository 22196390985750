
import AvatarImage from "@/components/AvatarImage.vue";
import UserStatusTemplate from "@/components/UserStatusTemplate.vue";
import Markup from "@/components/Markup";
import CommonServers from "./CommonServers.vue";
import CommonFriends from "./CommonFriends.vue";
import Badges from "./Badges.vue";
import { PresencesModule } from "@/store/modules/presences";
import userStatuses from "@/constants/userStatuses";

import {
  blockUser,
  fetchUser,
  ReturnedUser,
  UnblockUser,
} from "@/services/userService";
import { UsersModule } from "@/store/modules/users";
import friendlyDate from "@/utils/date";
import { PopoutsModule } from "@/store/modules/popouts";
import { FriendsModule } from "@/store/modules/friends";
import {
  acceptRequest,
  deleteFriend,
  sendFriendRequest,
} from "@/services/relationshipService";
import { CustomStatusesModule } from "@/store/modules/memberCustomStatus";
import { ChannelsModule } from "@/store/modules/channels";
import { MeModule } from "@/store/modules/me";
import Server from "@/interfaces/Server";
import { ServersModule } from "@/store/modules/servers";
import User from "@/interfaces/User";
import { getBadges } from "@/constants/badges";
import { defineAsyncComponent, PropType } from "vue";
import { defineComponent } from "vue";
const SafeHtml = defineAsyncComponent(
  () => import("@/components/SafeHtml.vue")
);
export default defineComponent({
  name: "ProfilePopout",
  components: {
    AvatarImage,
    Markup,
    UserStatusTemplate,
    CommonServers,
    CommonFriends,
    Badges,
    SafeHtml,
  },
  props: {
    data: {
      type: Object as PropType<{ id: string; fullProfile?: ReturnedUser }>,
      required: true,
    },
  },
  data() {
    return {
      returnedUser: null as ReturnedUser | null,
    };
  },
  computed: {
    bannerURL(): any {
      if (!this.user.banner) return null;
      return process.env.VUE_APP_NERTIVIA_CDN + this.user.banner;
    },
    joinedAt(): any {
      if (!this.returnedUser) return undefined;
      return friendlyDate(
        this.returnedUser?.user.created || 0,
        localStorage["timeFormat"]
      );
    },
    badgesArr(): any {
      const flags = (this.user as any).badges;
      if (!flags) return [];
      return getBadges(flags);
    },
    ageAndGender(): any {
      let finalText = "";
      if (this.aboutMe?.gender && this.aboutMe.gender !== "Rather not say") {
        finalText = this.aboutMe.gender;
      }
      if (this.aboutMe?.age && this.aboutMe.age !== "Rather not say") {
        if (finalText) {
          finalText += ", ";
        }
        finalText += this.aboutMe.age;
      }
      return finalText;
    },
    presence(): any {
      if (!this.user?.id) return userStatuses[0];
      const presence = PresencesModule.getPresence(this.user.id);
      return userStatuses[presence || 0];
    },
    customStatus(): any {
      if (this.presence.name === "Offline") return undefined;
      return CustomStatusesModule.customStatus[this.user.id];
    },
    friendStatus(): any {
      const status = FriendsModule.friends[this.user.id]?.status;
      return status;
    },
    user(): any {
      if (!this.returnedUser) {
        return UsersModule.users[this.data.id] || {};
      }
      return this.returnedUser.user;
    },
    aboutMe(): any {
      return this.returnedUser?.user.about_me;
    },
    isMe(): any {
      return this.user.id === MeModule.user.id;
    },
    isBlocked(): any {
      return UsersModule.blockedUserIDArr.includes(this.user.id);
    },
    commonServers(): any {
      const commonServers = this.returnedUser?.commonServersArr;
      if (!commonServers) return [];
      const servers: Server[] = [];
      for (let i = 0; i < commonServers.length; i++) {
        const serverID = commonServers[i];
        if (!ServersModule.servers[serverID]) continue;
        servers.push(ServersModule.servers[serverID]);
      }
      return servers;
    },
    commonFriends(): any {
      const commonFriends = this.returnedUser?.commonFriendsArr;
      if (!commonFriends) return [];
      const friends: User[] = [];
      for (let i = 0; i < commonFriends.length; i++) {
        const friendID = commonFriends[i];
        const friend = FriendsModule.friendWithUser(friendID);
        if (!friend?.recipient) continue;
        friends.push(friend.recipient);
      }
      return friends;
    },
  },
  mounted() {
    this.returnedUser = this.data.fullProfile || null;
    if (this.returnedUser) {
      return;
    }
    fetchUser(this.data.id).then((user) => {
      this.returnedUser = user;
    });
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout("profile");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    blockUser() {
      if (this.isBlocked) {
        UnblockUser(this.user.id);
      } else {
        blockUser(this.user.id);
      }
    },
    sendMessageButton() {
      ChannelsModule.LoadDmChannel(this.user.id);
      PopoutsModule.ClosePopout("profile");
    },
    cancelOrDecline() {
      deleteFriend(this.user.id);
    },
    acceptFriend() {
      acceptRequest(this.user.id);
    },
    addFriend() {
      sendFriendRequest(this.user.username, this.user.tag);
    },
    botCreatorClicked() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.returnedUser?.user.createdBy?.id },
        key: this.returnedUser?.user.createdBy?.id,
      });
    },
  },
});
