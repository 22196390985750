
import AvatarImage from "@/components/AvatarImage.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import User from "@/interfaces/User";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "CommonServers",
  components: { AvatarImage },
  props: {
    friends: {
      type: Array as PropType<User[]>,
      required: true,
    },
  },
  methods: {
    friendClicked(friend: User) {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: friend.id },
        key: friend.id,
      });
    },
  },
});
