
import Server from "@/interfaces/Server";
import AvatarImage from "@/components/AvatarImage.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import { LastSelectedServersModule } from "@/store/modules/lastSelectedServer";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "CommonServers",
  components: { AvatarImage },
  props: {
    servers: {
      type: Array as PropType<Server[]>,
      required: true,
    },
  },
  methods: {
    serverClicked(server: Server) {
      const serverChannelID = LastSelectedServersModule.lastServerChannelID(
        server.server_id || ""
      );
      this.$router.push(
        `/app/servers/${server.server_id}/${
          serverChannelID || server.default_channel_id
        }`
      );
      PopoutsModule.ClosePopout("profile");
    },
  },
});
