
import { Badge } from "@/constants/badges";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Badges",
  props: {
    badges: {
      type: Array as PropType<Badge[]>,
      required: true,
    },
  },
});
